@import "main.scss";
// -------- Added covid banner styles --------
.acpv-covid-banner{
  display: flex; 
  color: black; 
  padding: 10px; 
  text-decoration: none;
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
 &:hover{
  text-decoration: none;
 }
  .acpv-icon{
    margin-right: 5px;
    margin-left: 28px;
    width: 26px;

    @include respond-to('mobile') {
      margin-left: 7px;
    }
  
  
    @include respond-to('tablet') {
      margin-left: 48px;
    }
  }

  .covid-message{
    position: relative; 
    top: 1.3px;
  }

}
// --------

.home {
  max-width: 1900px;
  padding: 0;
  margin: 0 auto;
  [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  #main {
    .mobile-pano {
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      // @include responsive-full-bleed-mobile-only-background("-/media/EC7FBA344D9544659EBC8BD85C63DEF4.ashx");
      @include responsive-full-bleed-mobile-only-background("-/media/CC0D8464F0BD47C390C79AC8CA2AB896.ashx");

    }
  }
  /* End Main */

}
/* END .pano */

/* WITH OFFER */
.pano-with-offer {
  margin: 0;
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  
  @include responsive-full-bleed-with-offer-background(
    "-/media/CC0D8464F0BD47C390C79AC8CA2AB896.ashx",
    "-/media/Acura-Certified-Images/HomePage/ACPV_HOME_image.ashx",
    "-/media/Acura-Certified-Images/HomePage/ACPV_HOME_image.ashx"
    //"-/media/E2D999C0793940EB8E875B4E12D98734.ashx",
    //"-/media/E2D999C0793940EB8E875B4E12D98734.ashx"

  );
  #main-copy {
    text-align: center;
    margin: 0 auto;
    // width: 334px;
    margin-top: 20px;
    color: #000;
    margin-bottom: 30px;
    
    @include respond-to(sm) {
      width: 334px;
      color: #fff !important;
      margin-top: 92px;
      margin-left: 10px;
    }
    @include respond-to(md) {
      text-align: left;
      margin-top: 105px;
      margin-left: 40px;
    }
    h1 {
      font-family: $futura-extra-bold;
      font-size: 40px;
      line-height: 45px;
      //letter-spacing: 2px;

      @include respond-to(sm) {
        font-size: 42px;
        line-height: 55px;
        text-align: left;
        //letter-spacing: 5px;
      }
      @include respond-to(md) {
        line-height: 53px;
        font-size: 50px;
        //letter-spacing: 10px;
      }
    }
    > p {
      font-family: $acura-bespoke-book;
      font-size: 15px;
      margin-top: 18px;
      @include respond-to(sm) {
        margin-top: 18px;
      }
      @include respond-to(md) {
        margin-top: 18px;
      }
    }
    p.search-inventory-btn-covid {
      width: 255px;
      margin: 0 auto;
      margin-top: 25px;
      @include respond-to(sm) {
        
        width: 260px;
        margin-top: 40px;
      }
      @include respond-to(md) {
        margin-top: 50px;
        margin-bottom: 114px;
      }
      a {

        min-width: unset !important;
        width: inherit !important;
        @include respond-to(sm) {            
          color: #fff !important;
        }
      }
    }
  }
  #offers {
    margin: 0 auto;
    position: relative;
    @include respond-to(md) {
      width: 95%;
    }
    @include respond-to(lg) {
      width: 1080px;
    }
    p {
      color: #fa0016;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 27px;
      text-align: center;
    }
  }
  .header-copy {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    hr {
      width: 30px;
      height: 4px;
      background: #f53143;
      border-top: none !important;
      margin-top: 8px !important;
      margin-bottom: 0 !important;
    }
  }
}
.offers-small-device {
  display: none;
}
/* Offer pano */
#offers {
  position: relative;
  margin-top: 70px;
  @include respond-to(sm) {
    margin-top: 0;
  }
  @include respond-to(md) {
    margin-top: 50px;
  }
  p {
    color: #fa0016;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
    text-align: center;
    @include respond-to(sm) {
      bottom: 40px;
    }
  }
  .offersCarousel {
    .item {
      position: relative;
    }
    .home-promotion-iframe-disclaimer {
      position: absolute;
      width: 14%;
      height: 13%;
      left: 66%;
      bottom: 0;
      top: 63%;
      // background: rgb(98, 98, 100); opacity: 0.5;
      @include respond-to(sm) { 
        width: 49%;
        height: 19%;
        left: 0;
        top: 70%;
      }
      @include respond-to(md) {
        width: 18%;
        height: 30%;
        left: 28%;
        bottom: 0;
        top: 60%;
      }
      .disclaimer-callout {
        // background: red;
        // opacity: 0.4; 
        width: 100%;
        height: 100%;
        z-index: 50;
        display: block;
        cursor: pointer;
      }
    }
    .home-amp-iframe-disclaimer {
      position: absolute;
      width: 14%;
      height: 13%;
      left: 73%;
      bottom: 0;
      top: 66%;
      // background: blue; opacity: 0.5;
      @include respond-to(sm) { 
        width: 15%;
        height: 10%;
        left: 50%;
        top: 60%;
      }
      @include respond-to(md) {
        width: 10%;
        height: 20%;
        left: 50%;
        bottom: 0;
        top: 60%;
      }
      .disclaimer-callout {
        // background: red;
        // opacity: 0.4; 
        width: 100%;
        height: 100%;
        z-index: 50;
        display: block;
        cursor: pointer;
      }
    }
  }
  #offer1,
  #offer2,
  #offer3 {
    > img {
      width: 100%;
      height: auto;
    }
    /*background-image: url(/public/images/home/offer-mobile-1.jpg); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    height: 439px;

    @include respond-to(sm) {
      background-image: url(/public/images/home/offer-tablet-1.jpg); 
      height: 531px;
    }

    @include respond-to(md) {
      background-image: url(/public/images/home/offer-desktop-1.jpg); 
      height: 489px;
    }*/
    .offer-wrapper {
      text-align: center;
      padding-top: 42px;
      color: #fff;
      @include respond-to(sm) {
        padding-top: 32px;
      }
      @include respond-to(md) {
        padding-top: 40px;
        margin-left: 88px;
        text-align: left;
      }
      .cpo-logo {
        span {
          @include sprite($acura-cpo-logo-white-mobile);
          @include respond-to(sm) {
            @include sprite($acura-cpo-logo-white-laptop);
          }
          @include respond-to(md) {
            @include sprite($acura-cpo-logo-white-desktop);
          }
        }
      }
      .subhead {
        @include respond-to(md) {
          margin-top: 20px;
        }
      }
      .terms {
        margin-top: 32px;
        margin-bottom: 18px;
        @include respond-to(sm) {
          margin-top: 44px;
          margin-bottom: 15px;
        }
        @include respond-to(md) {
          margin-top: 18px;
          margin-bottom: 2px;
        }
        ul {
          display: table;
          margin: 0 auto;
          @include respond-to(md) {
            margin-left: 0;
          }
          li {
            display: table-cell;
            margin: 0;
            padding: 0;
            vertical-align: top;
            text-align: left;
            @include respond-to(sm) {
              padding-right: 45px;
            }
            @include respond-to(md) {
              text-align: center;
              vertical-align: middle;
              padding-right: 0;
            }
            &.apr {
              padding-right: 6px;
              @include respond-to(sm) {
                padding-right: 45px;
              }
              @include respond-to(md) {
                padding-right: 8px;
              }
            }
            &.hl-2a {
              font-size: 50px !important;
              @include respond-to(sm) {
                font-size: 67px;
              }
              @include respond-to(md) {
                font-size: 50px;
              }
            }
            &.cp-2 {
              font-size: 16px !important;
              @include respond-to(sm) {
                font-size: 25px;
              }
              @include respond-to(md) {
                font-size: 18px;
              }
            }
          }
        }
      }
      .offer-short-details {
        width: 300px;
        margin: 0 auto;
        @include respond-to(sm) {
          width: 530px;
        }
        @include respond-to(md) {
          width: 310px;
          margin-left: 0;
        }
        .offer-short-details-copy {
          line-height: 18px;
          @include respond-to(sm) {
            line-height: 30px;
          }
          @include respond-to(md) {
            line-height: 16px;
          }
        }
        .offer-expiration {
          margin-top: 26px;
          margin-bottom: 26px;
          @include respond-to(sm) {
            margin-top: 30px;
            margin-bottom: 36px;
          }
          @include respond-to(md) {
            margin-top: 13px;
            margin-bottom: 40px;
          }
        }
      }
      div.shop-now-btn {
        margin: 0 auto;
        width: 185px;
        @include respond-to(sm) {
          width: 230px;
        }
        @include respond-to(md) {
          margin-left: 0;
          width: 260px;
        }
        a {
          min-width: unset !important;
          width: inherit !important;
        }
      }
    }
    .see-offers {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 28px;
      color: #f30000;
      margin: 0 auto;
      width: 158px;
      @include respond-to(sm) {
        width: 148px;
      }
      @include respond-to(md) {
        width: 146px;
      }
    }
  }
  /* Begin Owl Carousel css */

  .owl-prev,
  .owl-next {
    position: absolute;
    top: 44%;
    @include respond-to(sm) {
      top: 41%;
    }
    @include respond-to(md) {
      top: 43%;
    }
  }
  .owl-prev img,
  .owl-next img {
    width: 14px;
    margin-top: 6px;
    height: auto;
    @include respond-to(sm) {
      margin-top: 7px;
    }
    @include respond-to(md) {
      width: 14px;
      margin-top: 6px;
    }
  }
  .owl-prev {
    background: rgba(0, 0, 0, 0.6) !important;
    border-radius: 0;
    margin: 0;
    left: 0;
    width: 34px;
    height: 47px;
    @include respond-to(sm) {
      left: 0px;
    }
  }
  .owl-next {
    background: rgba(0, 0, 0, 0.6) !important;
    border-radius: 0;
    margin: 0;
    right: 0;
    width: 34px;
    height: 47px;
    @include respond-to(sm) {
      right: 0px;
    }
  }
  .owl-theme .owl-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5.65%;
    @include respond-to(sm) {
      width: 12%;
      left: 44%;
    }
    @include respond-to(md) {
      width: 10%;
      left: 45%;
    }
  }
  .owl-theme .owl-dots .owl-dot span {
    background: #c1bebe !important;
    // width: 8px !important;
    // height: 8px !important;
    margin: 5px 8px !important;
    transition: all 0.1s ease-in-out 0s;
  }
  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: #000 !important;
    // width: 8px !important;
    // height: 8px !important;
  }
  /* End Owl Carousel css */
}
/* END WITH OFFER */
/* NO OFFER */
.pano-no-offer {
  margin: 0;
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @include responsive-full-bleed-no-offer-background(
    // "-/media/EC7FBA344D9544659EBC8BD85C63DEF4.ashx",
    "-/media/CC0D8464F0BD47C390C79AC8CA2AB896.ashx",        
    "-/media/2D474B8C063C4914AA3FF47DDC212BBA.ashx",
    "-/media/A8A10F2271D84D9C8EE5FAB8EB64FA1C.ashx"
  );
  #main-copy {
    text-align: center;
    margin: 0 auto;
    width: 280px;
    margin-top: 40px;
    @include respond-to(sm) {
      text-align: left;
      width: 290px;
      margin-top: 92px;
      margin-left: 60px;
    }
    @include respond-to(md) {
      text-align: left;
      margin-top: 105px;
      margin-left: 60px;
    }
    h1 {
      line-height: 46px;
      letter-spacing: 7px;
      @include respond-to(sm) {
        line-height: 55px;
        letter-spacing: 10px;
      }
      @include respond-to(md) {
        line-height: 60px;
        letter-spacing: 10px;
      }
    }
    > p {
      margin-top: 18px;
      @include respond-to(sm) {
        margin-top: 22px;
      }
      @include respond-to(md) {
        margin-top: 22px;
      }
    }
    p.search-inventory-btn-covid {
      width: 255px;
      margin: 0 auto;
      margin-top: 42px;
      @include respond-to(sm) {
        width: 260px;
        margin-top: 40px;
      }
      @include respond-to(md) {
        margin-top: 50px;
        margin-bottom: 114px;
      }
      a {
        min-width: unset !important;
        width: inherit !important;
      }
    }
  }
  /* END #main-copy */
  .header-copy {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    hr {
      width: 30px;
      height: 4px;
      background: #f53143;
      border-top: none !important;
      margin-top: 8px !important;
      margin-bottom: 0 !important;
    }
  }
  /* END header-copy */
}
/* END NO OFFER */

.program-benefits-wrapper,
.vehicle-lineup-wrapper {
  padding: 0;
  [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .header-divider {
    .header-copy {
      width: 250px;
      margin: 0 auto;
      margin-top: 90px;
      @include respond-to(sm) {
        margin-top: 48px;
        width: 100%;
      }
      @include respond-to(md) {
        margin-top: 118px;
        width: 100%;
      }
      h10 {
        line-height: 30px;
        @include respond-to(md) {
          line-height: normal;
        }
      }
      hr {
        width: 30px;
        height: 4px;
        background: #f53143;
        border-top: none !important;
        margin-top: 5px !important;
        margin-bottom: 0 !important;
        @include respond-to(md) {
          margin-top: 8px !important;
        }
      }
    }
  }
  /* END .header-divider */
  #vehicles {
    background: #f3f3f3;
    @include respond-to(sm) {
      margin-left: 24px !important;
      margin-right: 24px !important;
    }
    @include respond-to(md) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    [class*="col-"] {
      padding-left: 0 !important;
      padding-right: 0 !important;
      position: relative;
      .copy {
        position: absolute;
        top: 15%;
        left: 5%;
        z-index: 3;
        p {
          display: none;
          color: #333 !important;
        }
      }
    }
    margin-top: 48px;
    @include respond-to(md) {
      margin-top: 38px;
    }
    img {
      width: 100%;
      height: auto;
      display: block;
      position: relative;
    }
    .vehicle {
      a {
        &:before {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          -webkit-box-shadow: -1px 1px 18px 9px rgba(198, 198, 198, 0.66);
          -moz-box-shadow: -1px 1px 18px 9px rgba(198, 198, 198, 0.66);
          box-shadow: -1px 1px 18px 9px rgba(198, 198, 198, 0.66);
          background: #fff;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          content: "";
          position: absolute;
          transform: scale(0.9);
          transition: transform 0.2s ease;
          z-index: -1;
          opacity: 0;
        }
        &:focus {
          img {
            z-index: 3;
            outline: 3px solid #be1100;
            outline-offset: 4px;
          }
        }        
        &:hover {
          img {
            z-index: 3;
          }
          &:before {
            @include respond-to(sm) {
              animation: acpv-vehicles-animateHover linear 0.4s;
              animation-iteration-count: 1;
              transform-origin: 50% 50%;
              animation-fill-mode: forwards;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
              -webkit-box-shadow: -1px 1px 18px 9px rgba(198, 198, 198, 0.66);
              -moz-box-shadow: -1px 1px 18px 9px rgba(198, 198, 198, 0.66);
              box-shadow: -1px 1px 18px 9px rgba(198, 198, 198, 0.66);
              background: #fff;
              content: "";
              position: absolute;
              transform: scale(1);
              z-index: 3;
            }
          }
        }
      }
    }
    @keyframes acpv-vehicles-animateHover {
      0% {
        opacity: 0;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
      }
      100% {
        opacity: 1;
        top: -12px;
        right: -12px;
        bottom: -12px;
        left: -12px;
      }
    }
  }
  /* END #vehicles */
  #program-benefits {
    [class*="col-"] {
      position: relative;
    }
    margin-top: 60px;
    @include respond-to(sm) {
      margin-top: 38px;
    }
    @include respond-to(md) {
      margin-top: 32px;
    }
    .intro {
      #intro-copy {
        h2 {
          line-height: 50px;
          @include respond-to(md) {
            line-height: normal;
          }
        }
        > p {
          line-height: 26px;
          margin-top: 20px;
          padding: 0 10px;
          @include respond-to(sm) {
            padding: 0 60px;
          }
          @include respond-to(md) {
            line-height: normal;
            margin-top: 36px;
          }
        }
      }
    }
    .tile-grid {
      .tile {
        box-sizing: border-box;
        position: relative;
        text-align: center;
        max-width: 100%;
        padding: 25px 70px;

        @include respond-to(md) {
          padding: 20px 85px;
          height: 180px;
          &:nth-child(9) {
            &:after {
              border-bottom: none;
            }
            &:before {
              border-right: none;
            }
          }
          &:nth-child(2n) {
            &:before {
              border-right: none;
            }
          }
          &:before {
            content: "";
          }
        }
        @include respond-to(lg) {
          padding: 35px 55px;
          height: 200px;
          &:nth-child(7) {
            &:after {
              border-bottom: none;
            }
          }
          &:nth-child(8) {
            &:after {
              border-bottom: none;
            }
          }
          &:nth-child(9) {
            &:after {
              border-bottom: none;
            }
          }
          &:nth-child(2n) {
            &:before {
              border-right: 1px #c3c3c3 solid;
            }
          }
          &:nth-child(3n) {
            &:before {
              border-right: none;
            }
          }
          &:not(:nth-child(3n)) {
            &:before {
              content: "";
            }
          }
        }
        &:before {
          border-right: 1px #c3c3c3 solid;
          bottom: 25%;
          position: absolute;
          right: 0;
          top: 25%;
        }

        &:after {
          border-bottom: 1px #c3c3c3 solid;
          bottom: 0;
          left: 10%;
          position: absolute;
          right: 10%;
          content: "";
          @include respond-to(md) {
            left: 20%;
            right: 20%;
          }
          @include respond-to(lg) {
            left: 9%;
            right: 9%;
          }
        }

        .tile-title {
          margin-bottom: 8px;
          text-align: center;
          font-size: 18px;
        }
        .tile-description {
          display: flex;
          flex: 1 1 auto;
          line-height: 1.4;
          text-align: center;

          a.link {
            display: inline-block;
            font-size: 14px;

            > .chevron-right-red {
              position: relative;
              top: 3px;
            }
          }

          a {
            color: inherit;
            cursor: pointer;
            display: block;
          }

          .short,
          .long {
            flex: 0 0 100%;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            transition: transform 0.33s ease-out;
          }

          .short a {
            text-decoration: underline;
            padding-top: 30px;
            @include respond-to(md) {
              padding-top: 20px;
            }
          }

          .long a {
            margin: 8px auto 0;
          }

          .long {
            transform: translateX(-100%) rotateY(180deg);
          }

          &.flip {
            .short {
              transform: rotateY(180deg);
              a {
                display: none;
              }
            }
            .long {
              transform: translateX(-100%) rotateY(0deg);
            }
          }
        }
        .svg-icon {
          height: 1em;
          width: 1em;
        }
        .tile-icon {
          margin: 0 auto 10px;

          background-repeat: no-repeat;
          background-size: contain;
        }
        .warranty-icon {
          background-image: url("/public/images/certified-pre-owned/icons/warranty-icon.png");
          width: 40px;
          height: 40px;
          @include respond-to(md) {
            width: 50px;
            height: 55px;
          }
          @include respond-to(lg) {
            width: 35px;
            height: 45px;
          }
        }
        .icon-182 {
          background-image: url("/public/images/certified-pre-owned/icons/182-icon.svg");
          width: 106px;
          height: 46px;
          @include respond-to(lg) {
            width: 105px;
            height: 45px;
          }
        }
        .transpar-icon {
          height: 35px;
          width: 40px;
          background-image: url("/public/images/certified-pre-owned/icons/transparency-icon.png");
          @include respond-to(md) {
            width: 40px;
            height: 45px;
          }
        }
        .mileage-icon {
          width: 40px;
          height: 40px;
          background-image: url("/public/images/certified-pre-owned/icons/mileage-icon.png");
          @include respond-to(md) {
            width: 50px;
            height: 50px;
          }
          @include respond-to(lg) {
            width: 40px;
            height: 40px;
          }
        }
        .oil-icon {
          width: 30px;
          height: 30px;
          background-image: url("/public/images/certified-pre-owned/icons/oil-change-icon.png");
          @include respond-to(md) {
            width: 40px;
            height: 45px;
          }
          @include respond-to(lg) {
            width: 37px;
            height: 37px;
          }
        }
        .six-icon {
          width: 30px;
          height: 30px;
          background-image: url("/public/images/certified-pre-owned/icons/six-years-icon.png");
          @include respond-to(md) {
            width: 40px;
            height: 40px;
          }
        }
        .roadside-icon {
          width: 30px;
          height: 30px;
          background-image: url("/public/images/certified-pre-owned/icons/roadside-icon.png");
          @include respond-to(md) {
            width: 50px;
            height: 40px;
          }
          @include respond-to(lg) {
            width: 45px;
            height: 35px;
          }
        }
        .concierge-icon {
          width: 30px;
          height: 30px;
          background-image: url("/public/images/certified-pre-owned/icons/concierge-icon.png");
          @include respond-to(md) {
            width: 55px;
            height: 35px;
          }
          @include respond-to(lg) {
            width: 45px;
            height: 35px;
          }
        }
        .sirius-icon {
          width: 110px;
          height: 30px;
          background-image: url("/public/images/certified-pre-owned/icons/sirius-icon.png");
          @include respond-to(md) {
            width: 150px;
            height: 35px;
          }
          @include respond-to(lg) {
            width: 155px;
            height: 35px;
          }
        }
      }

      .tile-182 {
        font-size: 105px;
        font-size: 18px;
        .tile-icon-182 {
          font-size: 105px;
          margin: 0 auto -7px;
        }
      }
    }
    // .tile-grid END
    svg.svg-icon.tile-icon.tile-icon-182 {
      font-size: 100px;
      margin: -30px !important;
    }
    /*.discover-btn-wrapper {*/
    .discover-more-btn {
      margin: 0 auto;
      margin-top: 98px;
      margin-bottom: 98px;
      width: 216px;
      @include respond-to(sm) {
        width: 260px;
        margin-top: 50px;
        margin-bottom: 100px;
      }
      @include respond-to(md) {
        width: 350px;
        margin-top: 40px;
        margin-bottom: 110px;
      }
      a {
        min-width: unset !important;
        width: inherit !important;
      }
    }
  }
  /* END #program-benefits */
}

.dropdown-acpv{
  background-color: rgb(190, 17, 0);
  color: #fff;
  padding: 30px;

  @include respond-to('tablet'){
    margin-bottom: 70px;
  }

  .filters{
    width: 62%;
    margin: auto;

    @include respond-to('tablet-'){
      width: 100%;
    }

    @media only screen and (min-width: 992px) and (max-width: 1200px){
      width: 75%;
    }

    .space{
      padding-right: 0px;

      @include respond-to('tablet-'){
        margin-bottom: 15px;
      }
    }

    .filter-nav-sortby-btn-programs, .filter-nav-sortby-btn-vehicles {
      text-transform: uppercase;
      cursor: pointer;
      width: 100%;
      background-color: #fff;
      color: #000;
      border: 1px solid #ececec;
      text-align: left;
      padding: 10px 13px;
      z-index: 6;
      -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
      &:focus {
        outline: 2px solid #fff;
        outline-offset: 5px;
      }

      img{
        position: absolute;
        left: calc(100% - 27px);
        top: 14px;
      }

      .chevron-down-red{
        position: absolute;
        left: calc(100% - 34px);
        top: 14px;
      }
    }

    .filter-nav-sortby-inner-programs, .filter-nav-sortby-inner-vehicles {
      position: absolute;
      text-transform: uppercase;
      top: 0px;
      width: calc(100% - 15px);
      background-color: #fff;
      color: #333;
      border: 1px solid #ececec;
      z-index: 6;
      -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
      display: none;
    }

    .fnsb-item-programs, .fnsb-item-vehicles {
      border-bottom: 1px solid #ececec;
      text-align: left;
      background-repeat: no-repeat;
      background-position: 150px center;
      cursor: pointer;
      padding: 10px 13px;

      img{
        top: 8px;
        position: absolute;
        left: calc(100% - 28px);
      }
      
      .chevron-up-red{
        position: absolute;
        left: calc(100% - 30px);
        top: 8px;
      }
    }

    .fnsb-item-programs:last-of-type, .fnsb-item-vehicles:last-of-type{
      border-bottom: 0px solid #ececec;
    }

    .fnsb-item-programs:hover, .fnsb-item-vehicles:hover{
      background-color: rgb(240, 240, 240);
    }
    
    .search-button{
      color: #fff; 
      background-color: #be1100;
      border: 2px solid #fff; 
      width: 100%;
      height: 38px;
      transition: background-color 0.7s;
      &:focus {
        background-color: #000;
        color: #fff;
        border: 2px solid #be1100; 
        transition: background-color 0.7s;
        outline: 2px solid #fff;
        outline-offset: 5px;
      }
    }

    .search-button:hover{
      background-color: #000;
      color: #fff;
      border: 2px solid #be1100; 
      transition: background-color 0.7s;
    }
  }
}

.benefit-module-certified{
  background-color: rgb(0,0,0);
  color: white;
  padding: 30px 0px 0px 0px;
  font-family: $acura-bespoke-book;
  font-weight: normal;
  line-height: 24px;

  .hr-module-mobile {
    display: none;

    @include respond-to('mobile'){
      display: block;
      border-bottom: solid 1px white;
      padding-top: 20px;
      width: 50%;
      margin: 0 auto;
    }
  }

  .flex-content-container {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-wrap: wrap;
    }

    .benefit-card {
      flex: 1;
      padding: 13px;
      text-align: center;
      line-height: 22px;
      font-size: 14px;
      min-height: 158px;
      border-right: 1px solid red;

      @include respond-to("mobile") {
        border-right: 0;
      }

      .img-container {
        min-height: 60px;

        img {
          &.full-size {
            width: 141px;
          }

          width: 35px;
        }
      }

      .disclaimer-callout {
        color: white;
        font-size: 18px;
        &:focus {
          outline:2px solid #fff;
          outline-offset: 2px;
        }
      }
    }

    .benefit-card:nth-child(even) {
      @include respond-to("tablet") {
        border-right: 0;
      }
    }

    .benefit-card:last-child {
      @include respond-to("mobile") {
        margin-bottom: -40px;
      }
      @include respond-to("tablet") {
        margin-bottom: -40px;
      }
    }

    .flex-card {
      flex: 100%;

      @include respond-to("tablet") {
        flex: 50%;
      }
    }

    .benefit-card:last-child {
      border-right: 0;
    }
  }

  .subtitle{
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 35px;
  }

  .text-container {
    font-weight: 600;
  }

  .hr-certified{
    width: 50px;
    margin: auto;
    background-color: red;
    height: 5px;
    margin-top: 10px;
  }

  .module{
    text-align: center;
    height: 137px;
    padding: 1px;

    @include respond-to('tablet-'){
      margin-bottom: unset;
      height: auto;
    }

    .img-container{
      margin-bottom: 15px;

      img{
        width: 35px;
        
        .report {
          width: 125px;
        }
      }
    }

    .text-container{
      font-size: 14px;
    }

    .hr-module{
      background-color: red;
      width: .8px;
      height: 92%;
      position: relative;
      left: 100%;
      bottom: 93%;

      @include respond-to('tablet-'){
        display: none;
      }
    }

    .hr-module-mobile{
      background-color: red;
      width: 160px;
      height: .8px;
      position: relative;
      margin: 30px auto;
      display: none;

      @include respond-to('tablet-'){
        display: block;
      }
    }

    .disclaimer-callout{
      color: white;
      position: relative;
      top: 3px;
    }

    .hidden-xs{
      display: block;
      
      @include respond-to('tablet-'){
        display: none; 
      }
    }

    .show-xs{
      display: none;

      @include respond-to('tablet-'){
        display: block; 
      }
    }
  }

  .fourth-module{
    @include respond-to('tablet-'){
      margin-bottom: 30px;
    }
  }

  .button-certified{
    margin-bottom: 55px;
    margin-top: 47px;

    @include respond-to('tablet-'){
      margin-top: 10px;
    }

    text-align: center;

    .btn-certified{
      margin: auto;
      display: inline-block;
      color: #fff;
      background-color: #000;
      border: 2px solid red;
      font-family: $avenir-next-bold;
      font-size: 14px;
      padding: 7px 30px;

      &:hover{
        text-decoration: none;
        color: #000;
        background-color: #fff;
        transition: background-color .8s;
      }
      &:focus{
        text-decoration: none;
        color: #000;
        background-color: #fff;
        transition: background-color .8s;
        outline: 2px solid #fff;
        outline-offset: 5px;
      }
    }
  }
}

.benefit-module-used{
  background-color: rgb(243, 243, 243);
  color: #000;
  padding: 30px 0px 0px 0px;
  font-family: $acura-bespoke-book;
  font-weight: normal;
  line-height: 24px;

  .flex-content-container {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-wrap: wrap;
    }

    .benefit-card {
      flex: 1;
      padding: 13px;
      text-align: center;
      line-height: 22px;
      font-size: 14px;
      min-height: 158px;
      border-right: 1px solid red;

      @include respond-to("mobile") {
        border-right: 0;
      }

      .img-container {
        min-height: 60px;

        img {
          width: 35px;
        }
      }

      .disclaimer-callout {
        color: black;
        font-size: 18px;
        &:focus {
          outline:2px solid #000;
          outline-offset: 2px;
        }        
      }
    }

    .benefit-card:nth-child(even) {
      @include respond-to("tablet") {
        border-right: 0;
      }
    }

    .benefit-card:last-child {
      @include respond-to("mobile") {
        margin-bottom: -40px;
      }
      @include respond-to("tablet") {
        margin-bottom: -40px;
      }
    }

    .flex-card {
      flex: 100%;

      @include respond-to("tablet") {
        flex: 50%;
      }
    }

    .benefit-card:last-child {
      border-right: 0;
    }
  }

  .subtitle{
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 35px;
  }

  .text-container {
    font-weight: 600;
  }

  .hr-used{
    width: 50px;
    margin: auto;
    background-color: red;
    height: 5px;
    margin-top: 10px;
  }

  .hr-module-mobile {
    display: none;

    @include respond-to('mobile'){
      display: block;
      border-bottom: solid 1px red;
      padding-top: 20px;
      width: 50%;
      margin: 0 auto;
    }
  }

  .module{
    text-align: center;
    height: 137px;
    padding: 1px;

    @include respond-to('tablet-'){
      margin-bottom: 25px;
      height: 126px;
    }

    .img-container{
      margin-bottom: 5px;

      img{
        width: 35px;
      }
    }

    .text-container{
      font-size: 14px;
    }

    .hr-module{
      background-color: red;
      width: .8px;
      height: 94%;
      position: relative;
      left: 100%;
      bottom: 90%;

      @include respond-to('tablet-'){
        display: none;
      }
    }

    .hr-module-mobile{
      background-color: red;
      width: 160px;
      height: .8px;
      position: relative;
      margin: auto;
      margin-top: 25px;
      margin-bottom: 15px;
      display: none;

      @include respond-to('tablet-'){
        display: block;
      }
    }

    .disclaimer-callout{
      color: #000;
      position: relative;
      top: 3px;
    }

    .hidden-xs{
      display: block;
      
      @include respond-to('tablet-'){
        display: none; 
      }
    }

    .show-xs{
      display: none;

      @include respond-to('tablet-'){
        display: block; 
      }
    }
  }

  .third-module, .fourth-module{
    @include respond-to('tablet-'){
      margin: 0;
    }
  }

  .button-used{
    margin-bottom: 55px;
    margin-top: 47px;
    text-align: center;

    @include respond-to('tablet-'){
      margin-top: 10px;
    }

    .btn-used{
      margin: auto;
      display: inline-block;
      color: #000;
      background-color: rgb(243, 243, 243);
      border: 2px solid red;
      font-family: $avenir-next-bold;
      font-size: 14px;
      padding: 7px 30px;

      &:hover{
        text-decoration: none;
        color: rgb(243, 243, 243);
        background-color: #000;
        transition: background-color .8s;
      }
      &:focus{
        text-decoration: none;
        color: rgb(243, 243, 243);
        background-color: #000;
        transition: background-color .8s;
        outline:2px solid #000;
        outline-offset: 5px;
      }      
    }
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  width: 90%;
  max-width: 1380px;
  overflow: hidden;

  .amp-promo-banner {
    background: #0e63bd no-repeat url('/public/images/certified-pre-owned/Acura Maintence Banner-Desktop-Modal.png');
    background-size: cover;
    background-position: right;
    background-position-y: top;
    height: 497px;
    padding-top: 150px;
    padding-left: 50px;

    @include respond-to("desktopsm") {
      background: #0e63bd no-repeat url('/public/images/certified-pre-owned/Acura Maintence Banner-Desktop-Modal.png');/*tablet*/
      background-size: cover;
      background-position: right;
      background-position-y: top;
      height: 497px;
    }

    @include respond-to("tablet") {
      background: #0e63bd no-repeat url('/public/images/certified-pre-owned/Acura Maintence Banner-Desktop-Modal.png');/*tablet*/
      background-size: cover;
      background-position: right;
      background-position-y: top;
      height: 497px;
    }

    @include respond-to("mobile") {
      background: #0e63bd no-repeat url('/public/images/certified-pre-owned/Acura Maintence Banner-Mobile-Modal.png');
      background-size: cover;
      background-position: right;
      background-position-y: top;
      height: 497px;
      height: 720px;
      padding-left: 20px;
      padding-top: 400px;
    }
  }

  div {
    padding: 0;
  }
  p {
    font-size: 16px !important;
    line-height: 19px !important;
    padding: 20px !important;
    width: 40% !important;
    color: white;

    .disclaimer-callout {
      color: white;
    }

    @include respond-to("tablet") {
      font-size: 12px !important;
      line-height: 16px !important;
      width: 30% !important;
      padding: 10px 20px !important;
    }

    @include respond-to("mobile") {
      width: 100% !important;
      text-align: left !important;
    }
  }
  p:first-child {
    @include respond-to("tablet") {
      width: 55% !important;
    }
  }
  ul {
    font-size: 16px !important;
    line-height: 19px !important;
    padding: 0 20px !important;
    text-align: left;
    color: white;;

    @include respond-to("tablet") {
      font-size: 12px !important;
      line-height: 16px !important;
    }

    li {
      list-style-type: disc;
      margin-left: 20px;
    }
  }
}

/* The Close Button */
.close {
  color: #fff;
  float: right;
  font-size: 36px;
  font-weight: bold;
  opacity: 1;
  line-height: .5;
  margin-top: 20px;
  margin-right: 20px;
}

.close:hover,
.close:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

/* END .home */