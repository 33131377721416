
/*
  @desc Inserts img background inside div. The div is 100% page edge to edge
        and the 3 images will be shown as per our breakpoints, you pass the paths
        to the 3 images, order is: mobile, laptop and desktop. Mobile img will be used
        for both tablet and mobile. And in mobile mode the div will resize and maintain
        its aspect ratio
  @param $mobileImgPath, $laptopImgPath, $largeDesktopImgPath
  @author Harma Davtian
 */

@mixin responsive-full-bleed-background($mobileImgPath, $laptopImgPath, $largeDesktopImgPath){

  @include respond-to('mobile'){
    width: 100%;
    padding-bottom: 61%;
    background-image: url(#{$mobileImgPath});
    background-position: center top;
    background-size:contain;
  }

  @include respond-to('tablet'){
    height: 606px;
    background-image: url(#{$mobileImgPath});
  }

  @include respond-to('laptop'){
    height: 566px;
    background-image: url(#{$laptopImgPath});
  }

  @include respond-to('desktop+'){
    height: 700px;
    background-image: url(#{$largeDesktopImgPath});
  }
}


/* Responsive mixin for mobile pano ONLY */
@mixin responsive-full-bleed-mobile-only-background($mobileImgPath){

  @include respond-to('mobile'){
    width: 100%;
    padding-bottom: 61%;
    background-image: url(#{$mobileImgPath});
    background-position: center top;
    background-size:contain;
  }

}


/* Responsive mixin for pano with no offer (no background image for mobile) */
@mixin responsive-full-bleed-no-offer-background($mobileImgPath, $laptopImgPath, $largeDesktopImgPath){

  @include respond-to('mobile'){
    width: 100%;
    /*padding-bottom: 61%;*/
    background-position: center top;
    background-size:contain;
  }

  @include respond-to('tablet'){
    height: 606px;
    background-image: url(#{$laptopImgPath});
  }

  @include respond-to('laptop'){
    height: 566px;
    background-image: url(#{$laptopImgPath});
  }

  @include respond-to('desktop+'){
    height: 700px;
    background-image: url(#{$largeDesktopImgPath});
  }
}


/* Responsive mixin for pano with offers (taller pano at 1170px for desktop, no background image for mobile) */
@mixin responsive-full-bleed-with-offer-background($mobileImgPath, $laptopImgPath, $largeDesktopImgPath){

  @include respond-to('mobile'){
    width: 100%;
    /*padding-bottom: 61%;*/
    background-position: center top;
    background-size:contain;
  }

  @include respond-to('tablet') {
    height: 606px;
    background-image: url(#{$mobileImgPath});
  }

  @include respond-to('laptop'){
    height: 425px;
    background-image: url(#{$laptopImgPath});
  } 

  @include respond-to('desktop+'){
    height: 425px;
    background-image: url(#{$largeDesktopImgPath});
  }
}


@mixin red-line-under-header {
  position: relative;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: 27px;
    height: 4px;
    background-color: map-get($acpv-colors, red);
    left: 50%;
    margin-left: -13.5px;
    bottom: -8px;
  }
}